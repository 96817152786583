<template>
    <section id="description-list-alignment">
        <b-row class="match-height">
            <b-col cols="12">
                <b-card>
                    <b-card-title>{{ $t("QA") }} </b-card-title>
                    
                    <app-collapse accordion type="margin">
                        <app-collapse-item title="Q1">
                            {{ $t('A1') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q2">
                            {{ $t('A2') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q3">
                            {{ $t('A3') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q4">
                            {{ $t('A4') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q5">
                            {{ $t('A5') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q6">
                            {{ $t('A6') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q7">
                            {{ $t('A7') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q8">
                            {{ $t('A8') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q9">
                            {{ $t('A9') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q10">
                            {{ $t('A10') }}
                        </app-collapse-item>
                        <app-collapse-item title="Q11">
                            {{ $t('A11') }}
                        </app-collapse-item>
                    </app-collapse>
                </b-card>
            </b-col>
            <!--/ Description lists horizontal -->
        </b-row>
    </section>
</template>
  
<script>
import {
    BRow, BCol, BCard, BCardTitle, BCardText, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
    BFormRadioGroup, BFormRadio, BFormGroup,
} from 'bootstrap-vue'
import { codeCollapseTypes } from './code'
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardTitle,
        BCardText,
        BCardHeader, AppCollapseItem, AppCollapse, BCardCode,
        BCardBody, BFormRadioGroup, BFormRadio, BFormGroup
    },
    data() {
        return {
            collapseType: 'default',
            codeCollapseTypes,
        }
    },
}
</script>
  